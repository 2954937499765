<template>
  <div id="app">
    <JwlHeader v-if="showHeader" />
    <div class="container" v-if="error">
      <JwlError :jwl-error="{ trans: true, message: 'network.error.serverError', type: 'studyBreak' }"></JwlError>
    </div>
    <div class="container" v-if="loadingState === 1">
      <JwlIcon loading-icon margin-right />
      {{$t('general.loadingData')}}
    </div>
    <RouterView v-else-if="showView" />
    <JwlFooter />
    <jwlPoliciesOverlay v-if="showPolicyOverlay" />
    <jwlUserDataValidationOverlay v-if="showUserDataValidationOverlay" />

  </div>
</template>

<script>
import Vue, {
  defineComponent,
  onMounted,
  computed,
  ref, watch,
} from 'vue';
import UserAuthorisationHelper from '@base/plugins/UserAuthorisationHelper';

const ALLOWED_PATHS = ['login', 'handle_login', 'public_wbt', 'forgot_password', 'apply', 'announcement_subscription'];
const REDIRECT_URL = 'REDIRECT_URL';

export default defineComponent({
  name: 'App',
  setup () {
    let currentUser = null;
    const error = ref(false);
    const loadingState = ref(0);

    const router = useRouter();
    const route = useRoute();

    Vue.use(UserAuthorisationHelper, { router });

    const moduleStore = useModuleStore();
    const learningStore = useLearningStore();

    const showHeader = computed(() => !!route
      && !!route.name
      && !ALLOWED_PATHS.includes(route.name)
      && !!moduleStore.currentUser);

    const showView = computed(() => {
      let policyAccepted = false;
      let userDataValidated = false;
      if (moduleStore.currentUser) {
        policyAccepted = moduleStore.currentUser.policyAccepted;
        userDataValidated = moduleStore.currentUser.userDataValided;
      }
      return (!!moduleStore.currentUser && policyAccepted)
        || (route && !!route.name && ALLOWED_PATHS.includes(route.name));
    });

    const showPolicyOverlay = computed(() => !!moduleStore.currentUser
      && !moduleStore.currentUser.policyAccepted);

    const showUserDataValidationOverlay = computed(() => !!moduleStore.currentUser
      && !moduleStore.currentUser.userDataValidated);

    const changeLanguage = () => {
      if (route?.params?.lang) {
        moduleStore.SET_LOCALE(route?.params?.lang);
        learningStore.SET_LOCALE(route?.params?.lang);
      }
    };

    watch(route, changeLanguage);

    onMounted(() => {
      loadingState.value = 1;
      moduleStore.getBaseData()
        .then(() => {
          currentUser = moduleStore.currentUser;
          loadingState.value = 2;
        })
        .catch((e) => {
          if (e.message === 'network.error.serverError') {
            error.value = true;
          }
          loadingState.value = -1;
          if (route && route.name && !ALLOWED_PATHS.includes(route.name)) {
            localStorage.setItem(REDIRECT_URL, route.fullPath);
            router.push({
              name: 'login',
            });
          }
        });
    });

    return {
      currentUser,
      showHeader,
      showView,
      showPolicyOverlay,
      showUserDataValidationOverlay,
      error,
      loadingState,
    };
  },
});
</script>

<style lang="scss">
/*
 * Noto Naskh Arabic (Arabic) http://www.google.com/fonts/earlyaccess
 * copied from https://fonts.googleapis.com/earlyaccess/notonaskharabic.css
 * It's easiest to name it the same as the default Font, because only then can Roboto be used as fallback-font and not Times New Roman
 */
//noinspection ALL
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/NotoNaskhArabicUI-Regular.woff2') format('woff2'), url('./assets/fonts/NotoNaskhArabicUI-Regular.woff') format('woff');
  unicode-range: U+0600-06FF;
}

//noinspection ALL
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/NotoNaskhArabicUI-Bold.woff2') format('woff2'), url('./assets/fonts/NotoNaskhArabicUI-Bold.woff') format('woff');
  unicode-range: U+0600-06FF;
}

@import "@base/styles/jwlApp";

\:root {
  --page-color: var(--color-blue);
}

.math-tex{
  svg {
    height: unset;
    width: unset;
  }
}
</style>
